import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useReactiveVar } from '@apollo/client'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import type {
	AlertButton,
	AlertMessage,
	AlertOptions,
} from '@/components/Alert'
import Alert from '@/components/Alert'
import Notification from '@/components/Notification'

import { isLocalhost } from '@/lib/constants'
import { isEmpty } from '@/utils'
import { updateAvailableVar } from '@/utils/apollo/cache'

type AlertArguments = {
	message: AlertMessage
	buttons?: AlertButton[]
	options?: AlertOptions
}

export type BannerOptions = {
	/** Duration in seconds */
	duration?: number
	action?: { label: string; onClick: () => void }
}

type AlertContextTypes = {
	currentAlert: AlertArguments | null
	alert: (
		message: AlertMessage,
		buttons?: AlertButton[],
		optionsOrDescription?: AlertOptions,
	) => void
	banner: (title: string, message: string, options?: BannerOptions) => void
}

const AlertContext = createContext<AlertContextTypes>({
	currentAlert: null,
	alert: () => undefined,
	banner: () => undefined,
})

type Props = {
	children: ReactNode
}

const DEFAULT_DURATION = 4

const NOTIFICATION_DURATION = 1000

function AlertProvider(props: Props) {
	const { t } = useTranslation()
	const [currentAlert, setCurrentAlert] = useState<AlertArguments | null>(null)
	const [notifications, setNotifications] = useState<any[]>([])

	const updateAvailable = useReactiveVar(updateAvailableVar)

	useEffect(() => {
		let removeNotification: number
		if (notifications.length) {
			removeNotification =
				typeof window !== 'undefined'
					? window.setTimeout(() => {
							return setNotifications(([current, ...other]) =>
								current.remaining <= 0
									? (other ?? [])
									: [
											{
												...current,
												remaining: (current.remaining ?? current.duration) - 1,
											},
											...other,
										],
							)
						}, NOTIFICATION_DURATION)
					: -1
		}

		return () => {
			if (removeNotification && typeof window !== 'undefined') {
				window.clearTimeout(removeNotification)
			}
		}
	}, [notifications])

	function addNotificationToQueue({
		title,
		body,
		options = { duration: DEFAULT_DURATION },
	}: {
		title: string
		body: string
		options?: BannerOptions
	}): void {
		setNotifications((prev) => prev.concat({ title, body, options }))
	}

	useEffect(() => {
		if (isLocalhost) return

		switch (updateAvailable) {
			case 'offline':
				addNotificationToQueue({
					options: { duration: 8 },
					title: t('pwa.offlineMode.title'),
					body: t('pwa.offlineMode.body'),
				})
				break
			default:
				break
		}
	}, [updateAvailable, t])

	const banner = useCallback(
		(title: string, body: string, options?: BannerOptions) => {
			addNotificationToQueue({ title, body, options })
		},
		[],
	)

	const alert = useCallback(
		(
			message: AlertMessage,
			buttons?: AlertButton[],
			options?: AlertOptions,
		) => {
			setCurrentAlert({ message, buttons, options })
		},
		[],
	)

	function handleCloseAlert() {
		setCurrentAlert(null)
	}

	return (
		<AlertContext.Provider value={{ currentAlert, banner, alert }}>
			{currentAlert ? (
				<Alert alert={currentAlert} onClose={handleCloseAlert} />
			) : null}
			{!isEmpty(notifications) ? <Notification {...notifications[0]} /> : null}
			{props.children}
		</AlertContext.Provider>
	)
}

export const useNotify = (): AlertContextTypes => useContext(AlertContext)

export { AlertContext, AlertProvider }
