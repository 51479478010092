/* eslint-disable-next-line simple-import-sort/imports */
import '@/utils/sentry'
import '@/utils/posthog'
/* eslint-disable-next-line simple-import-sort/imports */

import { captureException } from '@sentry/react'
import { posthog } from 'posthog-js'
import ReactDOM from 'react-dom/client'

import { updateAvailableVar } from '@/utils/apollo/cache'
import { register as registerSW } from '@/utils/serviceWorker'

import Root from '@/Root'

const container = document.getElementById('root') as HTMLElement

try {
	if (typeof window.fetch === 'undefined') throw new Error('Missing fetch')
	if (typeof window.Promise === 'undefined') throw new Error('Missing Promise')
	if (typeof window.localStorage === 'undefined')
		throw new Error('Missing localStorage')
	if (typeof String.prototype.includes === 'undefined')
		throw new Error("Missing 'String.prototype.includes'")
	if (typeof Object.entries === 'undefined')
		throw new Error("Missing 'Object.entries'")
	if (typeof Object.fromEntries === 'undefined')
		throw new Error("Missing 'Object.fromEntries'")
	// Checks if it's any version of IE
	if ((window.document as any).documentMode)
		throw new Error('Is Internet Explorer')

	ReactDOM.createRoot(container).render(<Root />)

	window.addEventListener('appinstalled', () => {
		posthog.capture('appinstalled')
	})

	window.addEventListener('beforeinstallprompt', (event) => {
		event.preventDefault()
		posthog.capture('beforeinstallprompt')
	})

	registerSW({
		// Handle service worker register events
		onRegister(event) {
			updateAvailableVar(event.data.type)
		},
	})
} catch (error: any) {
	// eslint-disable-next-line no-console
	console.error(error)
	captureException(error)

	document.body.innerHTML +=
		'<center>' +
		'<div style="text-decoration:none;padding: 20px;margin: 20px;max-width: 600px;font-family: Helvetiva, Arial, sans-serif;text-align: center;border:3px solid #eee; border-radius: 20px;">' +
		'<center><img width="100px" src="/images/maskable-icon.png" /></center>' +
		'<h1 style="font-weight: 800;font-size:28px;">Por favor actualiza tu navegador para usar Cretia</h1>' +
		'<p>En Cretia la última y mejor tecnología disponible. Desafortunadamente el navegador que estás usando es obsoleto o está desactualizado.</p>' +
		'<p>Descarga uno de estos navegadores y estaremos listos: <a href="https://google.com/chrome">Chrome</a>, <a href="https://www.microsoft.com/edge">nuevo Edge</a> o <a href="https://mozilla.org/firefox/new">Firefox</a>.</p>' +
		'<p style="color:#666;font-size:14px;">¿Ya actualizaste pero aún no puedes accesar? envíanos un correo electrónico a <a href="mailto:support@cretia.app">support@cretia.app</a></p>' +
		'<code style="font-size:10px;color:#999;">' +
		error.message +
		'</code>' +
		'</div>' +
		'</center>'
}
